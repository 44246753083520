<template>
    <div class="non-hcp">
        帳號非HCP或權限有誤
    </div>
</template>
<script>
import liff from '@line/liff';
import { mapActions } from 'vuex';
export default {
    methods: {
        ...mapActions({
            clearUserInformation: 'auth/clearUserInformation',
        }),
        async retry() {
            liff.logout();
            await this.clearUserInformation();
        }
    }
};
</script>
<style lang="scss" scoped>
.non-hcp {
    padding: 100px 20px;
    text-align: center;
}
</style>
